body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.backgroundGradient {
  background: rgb(9, 50, 154) !important;
  background: linear-gradient(
    162deg,
    rgba(6, 44, 142, 1),
    75%,
    rgba(27, 125, 175, 1) 100%
  ) !important;
}

.backgroundGradientForCard {
  background: rgb(6, 44, 142) !important;
  background: linear-gradient(
    162deg,
    rgba(6, 44, 142, 1) 0%,
    rgba(27, 125, 175, 1) 50%,
    rgba(147, 208, 195, 1) 100%
  ) !important;
}

.backgroundGradientForLogin {
  background: rgb(6, 44, 142) !important;
  background: linear-gradient(
    0deg,
    rgba(6, 44, 142, 1) 0%,
    rgba(27, 125, 175, 1) 50%,
    rgba(147, 208, 195, 1) 100%
  ) !important;
}

.gradientForText {
  background-image: linear-gradient(
    0deg,
    rgba(6, 44, 142, 1) 0%,
    rgba(27, 125, 175, 1) 50%,
    rgba(147, 208, 195, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text; /* For older browsers */
  color: transparent;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-checkbox-box {
  border: 1px solid rgb(186, 186, 186);
}

.p-paginator > button {
  height: 12px;
}

.highcharts-credits {
  display: none;
}

.supportItems:hover,
.imagePreview:hover {
  scale: 1.05;
  transition: 0.2s;
  cursor: pointer;
}

.textHover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.feedbackButton {
  color: rgb(117, 117, 117);
}

.feedbackButton:hover {
  color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
